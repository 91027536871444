<template>
  <div class="orderDetailsDemo">
    <!-- <h2 class="orderDetailsTitle">提单详情</h2> -->
    <div class="flowPageBox">
      <h3 class="title">
        提货流程
        <span>提货单号：{{ detailsObj.detailsObj.takeGoodsNo }}</span>
      </h3>
      <!-- 流程图 -->
      <div class="flowBox">
        <div v-for="(item, index) in flowArr" :key="index" class="singleFlow">
          <p>{{ index + 1 }}</p>
          <span>{{ item.name }}</span><i />
          <b>{{ item.title }}</b>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <el-descriptions title="商品信息" :column="3">
      <el-descriptions-item label="下单时间">
        {{
          detailsObj.detailsObj.createTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="订单编号">
        {{
          detailsObj.detailsObj.orderNo
        }}
      </el-descriptions-item>
      <el-descriptions-item label="商家">
        {{
          detailsObj.detailsObj.cmpName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="商家联系人">
        {{
          `${detailsObj.detailsObj.listingPerson} ${detailsObj.detailsObj.personPhone}`
        }}
      </el-descriptions-item>
      <el-descriptions-item label="买家">
        {{
          detailsObj.detailsObj.buyerCmpName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="买家联系人">
        {{
          `${detailsObj.detailsObj.receivingPerson} ${detailsObj.detailsObj.receivingPhone}`
        }}
      </el-descriptions-item>
      <el-descriptions-item :span="3" label="自提地址">
        {{
          `${detailsObj.detailsObj.warehouseAddress} ${detailsObj.detailsObj.warehouseName} ${detailsObj.detailsObj.warehousePerson} ${detailsObj.detailsObj.warehousePhone}`
        }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelHidden" :span="3" content-class-name="table-descriptions">
        <Table
          :item-data="goodsTableItem.itemData"
          :list-data="goodsTableItem.listData"
          :loading="loading"
        />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 提货信息 -->
    <el-descriptions title="提货信息" :column="3">
      <el-descriptions-item label="预计提货日期">
        {{
          detailsObj.detailsObj.prePickUpTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="提货司机">
        {{
          detailsObj.detailsObj.driverName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{
          detailsObj.detailsObj.driverPhone
        }}
      </el-descriptions-item>
      <el-descriptions-item :span="3" label="车牌号">
        {{
          detailsObj.detailsObj.carCode
        }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3">
        <Table
          :item-data="pickTableItem.itemData"
          :list-data="pickTableItem.listData"
          :loading="loading"
        />
      </el-descriptions-item>
    </el-descriptions>
    <!-- <el-descriptions title="金额明细" :column="3">
      <el-descriptions-item label="支付方式">
        {{
          detailsObj.detailsObj.payStyle === "00" ? "全款支付" : "保证金预付"
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="订单总额"
      >
        ￥{{ detailsObj.detailsObj.orderAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        label="本次申请提取货款"
      >
        ￥{{ detailsObj.detailsObj.payTotalAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="已付保证金"
      >
        ￥{{
          detailsObj.detailsObj.bondAmount || 0
        }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="本次已抵扣保证金"
      >
        ￥{{ detailsObj.detailsObj.payBondAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="累计已抵扣保证金"
      >
        ￥{{ detailsObj.detailsObj.cumulativePayBondAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="detailsObj.detailsObj.payStyle !== '00'"
        label="本次提货支付"
      >
        ￥{{ detailsObj.detailsObj.payCopeAmount || 0 }}
      </el-descriptions-item>
    </el-descriptions> -->
    <!-- 提货审批信息 -->
    <el-descriptions title="提货审批信息" :column="3">
      <el-descriptions-item label="提货单状态">
        {{
          (
            $store.getters
              .getDictionaryItem("take_goods_bill_status")
              .find((item) => item.dictId === detailsObj.detailsObj.state) || {}
          ).dictName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="提货申请人">
        {{
          detailsObj.detailsObj.applyPerson
        }}
      </el-descriptions-item>
      <el-descriptions-item label="提货申请时间">
        {{
          detailsObj.detailsObj.applyTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核结果">
        <span v-if="detailsObj.detailsObj.reviewResult === '00'">未通过</span>
        <span
          v-else-if="detailsObj.detailsObj.reviewResult === '01'"
        >已通过</span>
      </el-descriptions-item>
      <el-descriptions-item label="审核人">
        {{
          detailsObj.detailsObj.reviewPerson
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核时间">
        {{
          detailsObj.detailsObj.reviewTime
        }}
      </el-descriptions-item>
      <el-descriptions-item label="审核备注">
        {{
          detailsObj.detailsObj.reviewRemark
        }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 出库信息 -->
    <el-descriptions title="出库信息" :column="3">
      <el-descriptions-item label="出库单号">
        {{
          detailsObj.detailsObj.outInventoryNo
        }}
      </el-descriptions-item>
      <el-descriptions-item label="出库时间">
        {{
          detailsObj.detailsObj.outInventoryTime
        }}
      </el-descriptions-item>
      <el-descriptions-item />
    </el-descriptions>
    <!-- <div class="form-operation">
      <el-button
        v-if="detailsObj.detailsObj.state === '04' && detailsObj.detailsObj.merchantDistribution !== 1"
        type="primary"
        class="widen-button"
        @click="viewPickingCode"
        >查看提货码</el-button
      >
    </div> -->
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { tradeTakeGoodsBillDetail, getTakeCode } from '@/api/warehousingSystem'
import { subPrecision } from '@/utils/util'

export default {
  components: { Table },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      total: 0,
      formData: {},
      listData: [],
      spaceActive: 1,
      goodsTableItem: {
        itemData: [
          { label: '品名/材质/规格', prop: 'goodsTwoCategoryName', width: 180 },
          { label: '钢厂/厂家', prop: 'manufactor', width: 180 },
          { label: '仓库', prop: 'warehouseName', width: 180 },
          // { label: '含税单价（元）', prop: 'unitPriceIncludingTax', type: 'money', width: 180 },
          { label: '订单数量/单位', prop: 'orderWeight', width: 100, unit: '吨' }
          // { label: '运费（元）', prop: 'orderFreight', type: 'money', width: 180 },
          // { label: '货款支付比例', prop: 'bondRate', width: 100 }
        ],
        listData: []
      },
      pickTableItem: {
        itemData: [
          { label: '订单数量/单位', prop: 'orderWeight', width: 180, unit: '吨' },
          { label: '累计申请数量/单位', prop: 'cumulativeWeight', width: 180, unit: '吨' },
          { label: '剩余可申请数量/单位', prop: 'remainingWeight', width: 180, unit: '吨' },
          { label: '本次申请量/单位', prop: 'applyTakeWeight', width: 180, unit: '吨' }
        ],
        listData: []
      },
      detailsObj: {
        detailsObj: {}
      },
      flowArr: [
        { name: '发起提货申请', title: '' },
        { name: '通过商家审核', title: '' },
        { name: '打印提货单', title: '获取提货码' },
        { name: '前往提货', title: '' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    viewPickingCode() {
      getTakeCode(this.$route.query.id || '', (res) => {
        this.$alert(
          `请凭提货码 ${res.data || ''} 至提货地点取货`,
          '提货码',
          {
            confirmButtonText: '知道了'
          }
        )
      })
    },
    toExamine() {
      this.dialogVisible = true
    },
    reviewConfirm() {
      this.dialogVisible = false
    },
    getDetails() {
      tradeTakeGoodsBillDetail(this.$route.query.id, res => {
        this.detailsObj.detailsObj = { ...res.data }
        this.pickTableItem.itemData.forEach(item => {
          if (item.unit)item.unit = this.detailsObj.detailsObj.unitMeasurement || '吨'
        })
        this.goodsTableItem.itemData.forEach(item => {
          if (item.unit)item.unit = this.detailsObj.detailsObj.unitMeasurement || '吨'
        })
        this.goodsTableItem.listData = [
          { 'goodsTwoCategoryName': res.data.goodsTwoCategoryName + '/' + res.data.goodsMaterialName + '/' + res.data.goodsSpecName,
            'manufactor': res.data.manufactor,
            'warehouseName': res.data.warehouseName,
            'unitPriceIncludingTax': res.data.unitPriceIncludingTax,
            'orderWeight': res.data.orderWeight,
            'orderFreight': res.data.orderFreight,
            'bondRate': '100%' }
        ]
        this.pickTableItem.listData = [
          {
            'orderWeight': res.data.orderWeight,
            'cumulativeWeight': subPrecision(Number(res.data.orderWeight), Number(res.data.remainingWeight)).toFixed(3),
            'remainingWeight': res.data.remainingWeight,
            'applyTakeWeight': res.data.applyTakeWeight
          }
        ]
        switch (res.data.state) {
          case '02':
            this.spaceActive = 1
            break
          case '04':
            this.spaceActive = 2
            break
          case '05':
            this.spaceActive = 3
            break
          case '06':
            this.spaceActive = 5
            break
          default:
            break
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .labelHidden {
  display: none;
}
/deep/ .table-descriptions {
  width: 100%;
}
.el-descriptions-item__content {
  flex: 1;
}
.orderDetailsDemo {
  background-color: #fff;
  padding: 24px;
  .unit {
    font-size: 14px;
    color: #333;
    margin-left: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  hr {
    opacity: 0.2;
  }
  .table {
    padding: 0 32px;
  }
 /deep/ .el-descriptions__body {
    padding: 24px;
  }
  .orderDetailsTitle {
    color: #000;
    font-size: 18px;
    padding-bottom: 16px;
  }
}
.flowPageBox {
  background-color: #fff;
  padding: 24px;
  .flowBox {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .singleFlow {
      position: relative;
      flex: 1;
      padding-right: 16px;
      display: flex;
      align-items: center;
      color:#0089FF;
      padding-bottom: 16px;
      b {
        position: absolute;
        top: 28px;
        left: 40px;
        font-size: 14px;
        color: #666;
        font-weight: 500;
      }
      span {
        padding: 0 16px;
      }
      i {
        display: inline-block;
        flex: 1;
        height: 2px;
        background-color:#0089FF;
      }
      p {
        display: inline-block;
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        background-color:#0089FF;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        font-style: normal;
      }
    }
    .singleFlow:last-of-type {
      // padding-right: 0;
      flex: none;
    }
  }
  .title {
    color: #333;
    font-size: 16px;
    padding-bottom: 32px;
    span {
      margin-left: 12px;
      color: #666;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
